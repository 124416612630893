import { Result, ApiResp } from "../../common";
import { ajax } from "../../utils";
import { BusinessType } from "../models";

interface MetaKey {
    id: number;
    encryptedMetaKey: string;
    businessType: BusinessType;
}

export interface ShareKeyInfo {
    id: number;
    metaKeys: MetaKey[];
}

export interface Form {
    vnoId: number;
    hashedShareKey: string;
}

export async function getMerchantShareKey(keyServerBase: string, form: Form): Promise<Result<ApiResp<ShareKeyInfo | undefined>, {
    code: number;
    msg: string;
    xhr?: XMLHttpRequest | undefined;
}>> {
    return ajax<{
        vnoId: number;
        shareKey: string;
    }, ApiResp<ShareKeyInfo | undefined>>(keyServerBase + "/api/web/xxx", {
        vnoId: form.vnoId,
        shareKey: form.hashedShareKey,
    });
}
