import { Result, ApiResp } from "../../common";
import { ajax } from "../../utils";
import { BusinessType } from "../models";

interface MetaKey {
    id: number;
    encryptedMetaKey: string;
    businessType: BusinessType;
}

export interface ShareKeyInfo {
    id: number;
    metaKeys: MetaKey[];
}

export interface Form {
    token: string;
    userId: number;
    brandId: number;
    hashedShareKey: string;
}

export async function getBrandShareKey(keyServerBase: string, form: Form): Promise<Result<ApiResp<ShareKeyInfo | undefined>, {
    code: number;
    msg: string;
    xhr?: XMLHttpRequest | undefined;
}>> {
    let headers: {
        [header: string]: string | string[];
    } = {
        token: form.token,
        operatorId: '' + form.userId
    };
    return ajax<{
        brandId: number;
        shareKey: string;
    }, ApiResp<ShareKeyInfo | undefined>>(keyServerBase + "/api/web/account/key/public/brand/sharekey/get/v1", {
        brandId: form.brandId,
        shareKey: form.hashedShareKey,
    }, {
        headers: headers
    });
}
