import { Option, Result, responseToResult, resultToPromise, ApiError } from "../common";
import { Bytes, AesKey256, RsaPriKey2048, RsaPubKey2048, sha256 } from "../crypto";
import { OwnerType, BusinessType } from "../api/models";
import { getEnterpriseKeyChain } from "../api/portal/getEnterpriseKeyChain";
import { tryDecryptEnterpriseKeyChain, tryDecryptBrandKeyChainByShareKey } from "../keychain";
import { Adapter } from "./index";
import { queryEncryptedMeta, EncryptedMeta } from "../api/portal/queryEncryptedMeta";
import { getBrandShareKey } from "../api/portal/getBrandShareKey";

export interface MetaKey {
    id: number;
    metaKey: AesKey256;
    businessType: BusinessType;
}

export interface MerchantParams {
    keyServerBase: string;
    getToken: () => Promise<string>;
    userId: number;
    getEnterpriseEncryptKey: () => Promise<AesKey256>;
}

interface EnterpriseKeyInfo {
    userRandomValue: string;
    rsaPrivateKey: RsaPriKey2048;
    rsaPublicKey: RsaPubKey2048;
    masterKey: AesKey256;
    metaKeys: MetaKey[];
}

interface ShareKeyInfo {
    id: number;
    ownerId: number;
    ownerType: OwnerType;
    brandId?: number;
    shareKey: AesKey256;
    metaKey?: MetaKey;
}

interface SharedKeyInfo {
    ownerId: number;
    metaKeyId: number;
    shareKey: AesKey256;
    metaKey: AesKey256;
}

export interface MerchantKeyChain {
    enterpriseKeyInfo: EnterpriseKeyInfo;
    shareKeyInfo: ShareKeyInfo[];
    sharedKeyInfo: SharedKeyInfo[];
}

export interface BrandShareParams {
    keyServerBase: string;
    getToken: () => Promise<string>;
    userId: number;
    brandId: number;
    shareKey: string;
}

export interface BrandShareKeyChain {
    brandId: number;
    shareKey: AesKey256;
    metaKeys: MetaKey[];
}

export const MerchantAdapter: Adapter<MerchantParams, MerchantKeyChain> = {
    async queryKeyChain(authParams: MerchantParams): Promise<Result<MerchantKeyChain | undefined, ApiError>> {
        let token = await Promise.resolve(authParams.getToken());
        let userId = authParams.userId;
        let enterpriseEncryptKey = await Promise.resolve(authParams.getEnterpriseEncryptKey());
        let respRet = await getEnterpriseKeyChain(authParams.keyServerBase, {
            token: token,
            userId: userId
        });
        let resp = await resultToPromise(respRet);
        return responseToResult(resp).map((cipherKeyChain): MerchantKeyChain | undefined => {
            return tryDecryptEnterpriseKeyChain(cipherKeyChain, enterpriseEncryptKey);
        });
    },
    lookupMetaKey(keyChain: MerchantKeyChain): {
        metaKeyId: number;
        metaKey: AesKey256;
    } | undefined {
        for (let item of keyChain.enterpriseKeyInfo.metaKeys) {
            if (BusinessType.Common == item.businessType) {
                return {
                    metaKeyId: item.id,
                    metaKey: item.metaKey
                };
            }
        }
        return undefined;
    },
    findMetaKeyById(keyChain: MerchantKeyChain, metaKeyId: number): Option<AesKey256> {
        let metaKey: Option<AesKey256> = Option.None();
        for (let item of keyChain.enterpriseKeyInfo.metaKeys) {
            if (metaKeyId == item.id) {
                metaKey.replace(item.metaKey);
                break;
            }
        }
        if (metaKey.isNone()) {
            for (let item of keyChain.sharedKeyInfo) {
                if (metaKeyId == item.metaKeyId) {
                    metaKey.replace(item.metaKey);
                    break;
                }
            }
        }
        return metaKey;
    },
    async queryEncryptedMeta(authParams: MerchantParams): Promise<Result<EncryptedMeta[], ApiError>> {
        let token = await Promise.resolve(authParams.getToken());
        let ret = await queryEncryptedMeta(authParams.keyServerBase, {
            token: token,
            userId: authParams.userId
        });
        let resp = await resultToPromise(ret);
        let result = responseToResult(resp);
        return result;
    }
};

export const BrandShareAdapter: Adapter<BrandShareParams, BrandShareKeyChain> = {
    async queryKeyChain(authParams: BrandShareParams): Promise<Result<BrandShareKeyChain | undefined, ApiError>> {
        let token = await Promise.resolve(authParams.getToken());
        let userId = authParams.userId;
        let brandId = authParams.brandId;
        let shareKey = authParams.shareKey;
        let hashedShareKey = sha256(Bytes.tryFromBase64(shareKey)).toBase64();
        let respRet = await getBrandShareKey(authParams.keyServerBase, {
            token: token,
            userId: userId,
            brandId: brandId,
            hashedShareKey: hashedShareKey
        });
        let resp = await resultToPromise(respRet);
        return responseToResult(resp).map((shareKeyInfo): BrandShareKeyChain | undefined => {
            let pubShareKey = AesKey256.tryFromBytes(Bytes.tryFromBase64(shareKey));
            const brandShareKeyChain = tryDecryptBrandKeyChainByShareKey(shareKeyInfo, pubShareKey, brandId);
            return brandShareKeyChain;
        });
    },
    lookupMetaKey(keyChain: BrandShareKeyChain): {
        metaKeyId: number;
        metaKey: AesKey256;
    } | undefined {
        return undefined;
    },
    findMetaKeyById(keyChain: BrandShareKeyChain, metaKeyId: number): Option<AesKey256> {
        let metaKey: Option<AesKey256> = Option.None();
        for (let item of keyChain.metaKeys) {
            if (metaKeyId == item.id) {
                metaKey.replace(item.metaKey);
                break;
            }
        }
        return metaKey;
    },
    async queryEncryptedMeta(authParams: BrandShareParams): Promise<Result<EncryptedMeta[], ApiError>> {
        let token = await Promise.resolve(authParams.getToken());
        let ret = await queryEncryptedMeta(authParams.keyServerBase, {
            token: token,
            userId: authParams.userId
        });
        let resp = await resultToPromise(ret);
        let result = responseToResult(resp);
        return result;
    }
};