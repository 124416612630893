import { Option, Result, responseToResult, resultToPromise, ApiError } from "../common";
import { Bytes, AesKey256, sha256 } from "../crypto";
import { tryDecryptMetaKeyByShareKey } from "../base";
import { Adapter } from "./index";
import { BusinessType } from "../api/models";
import { getShareKey } from "../api/enduser/getShareKey";
import { queryEncryptedMeta, EncryptedMeta } from "../api/enduser/queryEncryptedMeta";

export interface MetaKey {
    id: number;
    metaKey: AesKey256;
    businessType: BusinessType;
}

export interface MerchantShareParams {
    keyServerBase: string;
    vnoId: number;
    shareKey: string;
}

export interface MerchantShareKeyChain {
    vnoId: number;
    shareKey: AesKey256;
    metaKey: MetaKey;
}

export interface AuthParams {
    keyServerBase: string;
    shareKey: string;
    uuid: string;
    lang: string;
    appVersionCode: number;
    appId: number;
    brandId: number;
    vnoId: number;
    deviceInfo: object;
    originType: number;
    requestTimeTs: number;
    mac: string;
    token: string;
    accountName: string;
    clientType: string;
}

export interface KeyChain {
    brandId: number;
    shareKey: AesKey256;
    metaKeys: MetaKey[];
}

export const ShareAdapter: Adapter<AuthParams, KeyChain> = {
    async queryKeyChain(authParams: AuthParams): Promise<Result<KeyChain | undefined, ApiError>> {
        let brandId = authParams.brandId;
        let appId = authParams.appId;
        let shareKey = authParams.shareKey;
        let hashedShareKey = sha256(Bytes.tryFromBase64(shareKey)).toBase64();
        let respRet = await getShareKey(authParams.keyServerBase, {
            vnoId: authParams.vnoId,
            brandId: brandId,
            appId: appId,
            hashedShareKey: hashedShareKey,
            uuid: authParams.uuid,
            lang: authParams.lang,
            appVersionCode: authParams.appVersionCode,
            deviceInfo: authParams.deviceInfo,
            originType: authParams.originType,
            requestTimeTs: authParams.requestTimeTs,
            mac: authParams.mac,
            token: authParams.token,
            accountName: authParams.accountName,
            clientType: authParams.clientType
        });
        let resp = await resultToPromise(respRet);
        return responseToResult(resp).map((shareKeyInfo): KeyChain | undefined => {
            let pubShareKey = AesKey256.tryFromBytes(Bytes.tryFromBase64(shareKey));
            return {
                brandId: brandId,
                shareKey: pubShareKey,
                metaKeys: shareKeyInfo.encMetaKeys.map((metaKey) => {
                    return {
                        id: metaKey.metaKeyId,
                        metaKey: tryDecryptMetaKeyByShareKey(Bytes.tryFromBase64(metaKey.encMetaKey), pubShareKey),
                        businessType: metaKey.businessType
                    };
                })
            };
        });
    },
    lookupMetaKey(keyChain: KeyChain): {
        metaKeyId: number;
        metaKey: AesKey256;
    } | undefined {
        return undefined;
    },
    findMetaKeyById(keyChain: KeyChain, metaKeyId: number): Option<AesKey256> {
        let metaKey: Option<AesKey256> = Option.None();
        for (let item of keyChain.metaKeys) {
            if (metaKeyId == item.id) {
                metaKey.replace(item.metaKey);
                break;
            }
        }
        return metaKey;
    },
    async queryEncryptedMeta(authParams: AuthParams): Promise<Result<EncryptedMeta[], ApiError>> {
        let ret = await queryEncryptedMeta(authParams.keyServerBase, {
            vnoId: authParams.vnoId,
            brandId: authParams.brandId,
            appId: authParams.appId,
            uuid: authParams.uuid,
            lang: authParams.lang,
            appVersionCode: authParams.appVersionCode,
            deviceInfo: authParams.deviceInfo,
            originType: authParams.originType,
            requestTimeTs: authParams.requestTimeTs,
            requestId: Bytes.newRandom(32).toHex(),
            serviceToken: authParams.token,
            mac: authParams.mac,
            clientType: authParams.clientType
        });
        let resp = await resultToPromise(ret);
        let result = responseToResult(resp);
        return result;
    }
};