import { Result, ApiResp } from "../../common";
import { ajax } from "../../utils";
import { OwnerType, BusinessType } from "../models";

interface MetaKey {
    id: number;
    encryptedMetaKey: string;
    businessType: BusinessType;
}

interface EnterpriseKeyInfo {
    userRandomValue: string;
    encryptedRsaPrivateKey: string;
    rsaPublicKey: string;
    encryptedMasterKey: string;
    encryptedEnterprisePwd: string;
    metaKeys: MetaKey[];
}

interface SharedKeyInfo {
    ownerId: number;
    metaKeyId: number;
    encryptedShareKey: string;
    encryptedMetaKey: string;
}

interface ShareKeyInfo {
    id: number;
    ownerId: number;
    ownerType: OwnerType;
    brandId?: number;
    encryptedKey: string;
}

export interface EnterpriseKeyChain {
    enterpriseKeyInfo: EnterpriseKeyInfo;
    shareKeyInfo: ShareKeyInfo[];
    sharedKeyInfo: SharedKeyInfo[];
}

export interface Form {
    token: string;
    userId: number;
    brandId?: number;
}

export async function getEnterpriseKeyChain(keyServerBase: string, form: Form): Promise<Result<ApiResp<EnterpriseKeyChain | undefined>, {
    code: number;
    msg: string;
    xhr?: XMLHttpRequest | undefined;
}>> {
    let headers: {
        [header: string]: string | string[];
    } = {
        token: form.token,
        operatorId: '' + form.userId
    };
    if (form.brandId) {
        headers.brandId = '' + form.brandId;
    }
    return ajax<null, ApiResp<EnterpriseKeyChain | undefined>>(keyServerBase + "/api/web/account/key/enterprise/keychain/get/v1", null, {
        headers: headers
    });
}
