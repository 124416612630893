import { Option, Result } from "./common";

/**
 * ajax请求方法（只发post请求）
 * @param {String} url 请求接口
 * @param {Req} data 数据对象
 * @param {Object} opt 可选项
 *                     opt.method请求方法，默认为POST
 *                     opt.raw为true表示data是原始格式，不进行转码，默认为false
 *                     opt.contentType为表示Content-Type请求头字符串，只在opt.raw为true的时候使用，默认为"text/plain"
 *                     opt.json只在opt.raw为false的时候有效，默认为false
 *                               opt.json为true的时候对data做json序列化，Content-Type会发送application/json
 *                               opt.json为false的时候对data做表单序列化，Content-Type会发送application/x-www-form-urlencoded
 *                     opt.withCredentials为true的时候支持跨域请求
 *                     opt.headers为额外的请求头
 *                     opt.onUploadProgress上传进度钩子函数，参数类型为ProgressEvent
 * @return {Promise<Result<Resp, {code: number;msg: string;xhr?: XMLHttpRequest;}>>}
 */
export function ajax<Req, Resp>(url: string, data: Req, opt?: {
    method?: "GET" | "POST" | "PUT" | "DELETE";
    raw?: boolean;
    multipart?: boolean;
    contentType?: string;
    json?: boolean;
    disableClean?: boolean;
    withCredentials?: boolean;
    headers?: {
        [header: string]: string | string[];
    };
    onUploadProgress?: (evt: ProgressEvent<EventTarget>) => void;
}): Promise<Result<Resp, {
    code: number;
    msg: string;
    xhr?: XMLHttpRequest;
}>> {
    return new Promise(function (resolve, reject) {
        let xhr: XMLHttpRequest;
        if (window.XMLHttpRequest) {
            xhr = new window.XMLHttpRequest();
        } else if (window.ActiveXObject) {
            xhr = new window.ActiveXObject("Microsoft.XMLHTTP");
        } else {
            resolve(Result.Err({
                code: 1,
                msg: "ajax not supported"
            }));
            return;
        }
        xhr.onreadystatechange = function () {
            if (4 == xhr.readyState) {
                if (200 <= xhr.status && 300 > xhr.status) {
                    let resp = null;
                    try {
                        resp = JSON.parse(xhr.responseText);
                    } catch (ex) {
                    }
                    if (resp) {
                        resolve(Result.Ok(resp));
                    } else {
                        resolve(Result.Err({
                            code: 4,
                            msg: "not json response",
                            xhr: xhr
                        }));
                    }
                } else if (0 == xhr.status) {
                    resolve(Result.Err({
                        code: 2,
                        msg: "network error",
                        xhr: xhr
                    }));
                } else {
                    resolve(Result.Err({
                        code: 3,
                        msg: "server error",
                        xhr: xhr
                    }));
                }
            }
        };
        opt = opt || {};
        if (opt.onUploadProgress) {
            const onUploadProgress = opt.onUploadProgress;
            xhr.upload.onprogress = function (evt: ProgressEvent<EventTarget>) {
                onUploadProgress(evt);
            };
        }
        const method = opt.method || "POST";
        if ("GET" === method) {
            let queryString;
            if (opt.raw) {
                queryString = data;
            } else {
                data = data || {} as Req;
                queryString = Object.keys(data)
                    .filter((key) => undefined !== (data as any)[key])
                    .map(function (key) {
                        return key + "=" + encodeURIComponent((data as any)[key]);
                    }).join("&");
            }
            if (queryString) {
                if (url.includes("?")) {
                    url = url + "&" + queryString;
                } else {
                    url = url + "?" + queryString;
                }
            }
        }
        xhr.open(method, url, true);
        if (opt.headers) {
            const headers = opt.headers;
            Object.keys(headers).forEach((header) => {
                const vals = headers[header];
                if (undefined === vals || null === vals) {
                    return;
                }
                if ("string" === typeof vals || !vals.forEach) {
                    xhr.setRequestHeader(header, "" + vals);
                } else {
                    vals.forEach((val) => {
                        xhr.setRequestHeader(header, val);
                    });
                }
            });
        }
        let body: any = null;
        if ("POST" === method || "PUT" === method) {
            if (opt.raw) {
                body = data;
                if (!opt.multipart) {
                    const contentType = opt.contentType || "text/plain";
                    xhr.setRequestHeader("Content-Type", contentType + "; charset=UTF-8");
                }
            } else {
                data = data || {} as Req;
                if (undefined === opt.json || null === opt.json || opt.json) {
                    body = JSON.stringify(data);
                    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
                } else {
                    body = Object.keys(data)
                        .filter((key) => undefined !== (data as any)[key])
                        .map(function (key) {
                            return key + "=" + encodeURIComponent((data as any)[key]);
                        }).join("&");
                    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
                }
            }
        }
        if (opt.withCredentials) {
            xhr.withCredentials = true;
        }
        xhr.send(body as any);
    });
}
