import { Result, ApiResp } from "../../common";
import { ajax } from "../../utils";
import { BusinessType } from "../models";

interface MetaKey {
    id: number;
    encryptedMetaKey: string;
    businessType: BusinessType;
}

export interface ShareKeyInfo {
    id: number;
    metaKeys: MetaKey[];
}

export interface Form {
    brandId: number;
    hashedShareKey: string;
}

export async function getBrandShareKey(keyServerBase: string, form: Form): Promise<Result<ApiResp<ShareKeyInfo | undefined>, {
    code: number;
    msg: string;
    xhr?: XMLHttpRequest | undefined;
}>> {
    return ajax<{
        brandId: number;
        shareKey: string;
    }, ApiResp<ShareKeyInfo | undefined>>(keyServerBase + "/api/web/xxx", {
        brandId: form.brandId,
        shareKey: form.hashedShareKey,
    });
}
