import { Result, ApiResp } from "../../common";
import { ajax } from "../../utils";

interface ReqField {
    // 属性的路径扁平化表达，语法参考后文：扁平化属性表示方法约定
    path: string;
}

interface RespField {
    // 属性的路径扁平化表达，语法参考后文：扁平化属性表示方法约定
    path: string;
}

interface OriginalApiMeta {
    api: string; //api路径(需要支持正则表达式)
    request: {
        fields: ReqField[];
    };
    response: {
        fields: RespField[];
    };
}

export enum MetaType {
    File = 1, //文件类型
    Api = 0, //api类型
}

export interface FileMeta {
    //指定文件的名字标记，比如channelLogo
    label: string;
}

interface OriginalEncryptedMeta {
    metaType: MetaType; //meta数据类型
    metaData: FileMeta | OriginalApiMeta; //详细的meta数据
}

export interface ApiMeta {
    api: RegExp; //api路径(需要支持正则表达式)
    request: {
        fields: ReqField[];
    };
    response: {
        fields: RespField[];
    };
}

export interface EncryptedMeta {
    metaType: MetaType; //meta数据类型
    metaData: FileMeta | ApiMeta; //详细的meta数据
}

export interface Form {
    token: string;
    userId: number;
    // brandId?: number;
}

export async function queryEncryptedMeta(keyServerBase: string, form: Form): Promise<Result<ApiResp<EncryptedMeta[]>, {
    code: number;
    msg: string;
    xhr?: XMLHttpRequest | undefined;
}>> {
    let headers: {
        [header: string]: string | string[];
    } = {
        token: form.token,
        operatorId: '' + form.userId
    };
    // if (form.brandId) {
    //     headers.brandId = '' + form.brandId;
    // }
    let ret = await ajax<null, ApiResp<OriginalEncryptedMeta[]>>(keyServerBase + "/api/web/account/key/e2ee/meta/list/v1", null, {
        headers: headers
    });
    return ret.map((resp): ApiResp<EncryptedMeta[]> => {
        if (0 == resp.errCode) {
            return {
                totalCount: resp.totalCount,
                errCode: resp.errCode,
                message: resp.message,
                result: resp.result.map((encryptedMeta): EncryptedMeta => {
                    if (MetaType.Api == encryptedMeta.metaType) {
                        let apiMeta = encryptedMeta.metaData as OriginalApiMeta;
                        return {
                            metaType: encryptedMeta.metaType,
                            metaData: {
                                api: new RegExp(apiMeta.api),
                                request: apiMeta.request,
                                response: apiMeta.response,
                            }
                        };
                    } else {
                        return encryptedMeta as EncryptedMeta;
                    }
                })
            };
        } else {
            return resp as any;
        }
    });
}
