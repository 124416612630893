import { Option, Result, responseToResult, resultToPromise, ApiError } from "../common";
import { Bytes, AesKey256, sha256 } from "../crypto";
import { BusinessType } from "../api/models";
import { tryDecryptMerchantKeyChainByShareKey, tryDecryptBrandKeyChainByShareKey } from "../keychain";
import { Adapter } from "./index";
import { queryEncryptedMeta, EncryptedMeta } from "../api/thirdParty/queryEncryptedMeta";
import { getMerchantShareKey } from "../api/thirdParty/getMerchantShareKey";
import { getBrandShareKey } from "../api/thirdParty/getBrandShareKey";

export interface MetaKey {
    id: number;
    metaKey: AesKey256;
    businessType: BusinessType;
}

export interface MerchantShareParams {
    keyServerBase: string;
    vnoId: number;
    shareKey: string;
}

export interface MerchantShareKeyChain {
    vnoId: number;
    shareKey: AesKey256;
    metaKeys: MetaKey[];
}

export interface BrandShareParams {
    keyServerBase: string;
    token: string;
    userId: number;
    brandId: number;
    shareKey: string;
}

export interface BrandShareKeyChain {
    brandId: number;
    shareKey: AesKey256;
    metaKeys: MetaKey[];
}

export const MerchantShareAdapter: Adapter<MerchantShareParams, MerchantShareKeyChain> = {
    async queryKeyChain(authParams: MerchantShareParams): Promise<Result<MerchantShareKeyChain | undefined, ApiError>> {
        let vnoId = authParams.vnoId;
        let shareKey = authParams.shareKey;
        let hashedShareKey = sha256(Bytes.tryFromBase64(shareKey)).toBase64();
        let respRet = await getMerchantShareKey(authParams.keyServerBase, {
            vnoId: vnoId,
            hashedShareKey: hashedShareKey
        });
        let resp = await resultToPromise(respRet);
        return responseToResult(resp).map((shareKeyInfo): MerchantShareKeyChain | undefined => {
            let pubShareKey = AesKey256.tryFromBytes(Bytes.tryFromBase64(shareKey));
            const merchantShareKeyChain = tryDecryptMerchantKeyChainByShareKey(shareKeyInfo, pubShareKey, vnoId);
            return merchantShareKeyChain;
        });
    },
    lookupMetaKey(keyChain: MerchantShareKeyChain): {
        metaKeyId: number;
        metaKey: AesKey256;
    } | undefined {
        return undefined;
    },
    findMetaKeyById(keyChain: MerchantShareKeyChain, metaKeyId: number): Option<AesKey256> {
        let metaKey: Option<AesKey256> = Option.None();
        for (let item of keyChain.metaKeys) {
            if (metaKeyId == item.id) {
                metaKey.replace(item.metaKey);
                break;
            }
        }
        return metaKey;
    },
    async queryEncryptedMeta(authParams: MerchantShareParams): Promise<Result<EncryptedMeta[], ApiError>> {
        let ret = await queryEncryptedMeta(authParams.keyServerBase, {});
        let resp = await resultToPromise(ret);
        let result = responseToResult(resp);
        return result;
    }
};

export const BrandShareAdapter: Adapter<BrandShareParams, BrandShareKeyChain> = {
    async queryKeyChain(authParams: BrandShareParams): Promise<Result<BrandShareKeyChain | undefined, ApiError>> {
        let brandId = authParams.brandId;
        let shareKey = authParams.shareKey;
        let hashedShareKey = sha256(Bytes.tryFromBase64(shareKey)).toBase64();
        let respRet = await getBrandShareKey(authParams.keyServerBase, {
            brandId: brandId,
            hashedShareKey: hashedShareKey
        });
        let resp = await resultToPromise(respRet);
        return responseToResult(resp).map((shareKeyInfo): BrandShareKeyChain | undefined => {
            let pubShareKey = AesKey256.tryFromBytes(Bytes.tryFromBase64(shareKey));
            const brandShareKeyChain = tryDecryptBrandKeyChainByShareKey(shareKeyInfo, pubShareKey, brandId);
            return brandShareKeyChain;
        });
    },
    lookupMetaKey(keyChain: BrandShareKeyChain): {
        metaKeyId: number;
        metaKey: AesKey256;
    } | undefined {
        return undefined;
    },
    findMetaKeyById(keyChain: BrandShareKeyChain, metaKeyId: number): Option<AesKey256> {
        let metaKey: Option<AesKey256> = Option.None();
        for (let item of keyChain.metaKeys) {
            if (metaKeyId == item.id) {
                metaKey.replace(item.metaKey);
                break;
            }
        }
        return metaKey;
    },
    async queryEncryptedMeta(authParams: BrandShareParams): Promise<Result<EncryptedMeta[], ApiError>> {
        let ret = await queryEncryptedMeta(authParams.keyServerBase, {});
        let resp = await resultToPromise(ret);
        let result = responseToResult(resp);
        return result;
    }
};