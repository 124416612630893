import { Result, ApiResp } from "../../common";
import { ajax } from "../../utils";
import { BusinessType } from "../models";

interface MetaKey {
    metaKeyId: number;
    encMetaKey: string;
    businessType: BusinessType;
}

export interface ShareKeyInfo {
    shareKeyId: number;
    timestamp: number;
    encMetaKeys: MetaKey[];
}

export interface Form {
    uuid: string;
    lang: string;
    appVersionCode: number;
    appId: number;
    brandId: number;
    vnoId: number;
    deviceInfo: object;
    originType: number;
    requestTimeTs: number;
    mac: string;
    token: string;
    accountName: string;
    hashedShareKey: string;
    clientType: string;
}

export async function getShareKey(keyServerBase: string, form: Form): Promise<Result<ApiResp<ShareKeyInfo | undefined>, {
    code: number;
    msg: string;
    xhr?: XMLHttpRequest | undefined;
}>> {
    return ajax<Form, ApiResp<ShareKeyInfo | undefined>>(keyServerBase + "/liveapi/api/v5/account/key/info/query", form);
}
