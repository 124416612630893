import { Option, Result, Enum2, Enum3, resultToPromise, ApiError } from "./common";
import { Bytes, AesKey256 } from "./crypto";
import { buildCacheMgr, CacheMgr } from "./cache";
import { cleanData } from "./clean";
import { Adapter } from "./adapter";
import { encodeBlob, tryDecodeBlob, BlobData } from "./encoder";
import { EncryptedMeta, MetaType, ApiMeta, FileMeta } from "./api/portal/queryEncryptedMeta";
import {
    MerchantParams,
    MerchantAdapter,
    BrandShareParams as PortalBrandShareParams,
    BrandShareAdapter as PortalBrandShareAdapter
} from "./adapter/portal";
import {
    AuthParams as EnduserAuthParams,
    ShareAdapter as EnduserShareAdapter
} from "./adapter/enduser";
import {
    MerchantShareParams as ThirdPartyMerchantParams,
    MerchantShareAdapter as ThirdPartyMerchantShareAdapter,
    BrandShareParams as ThirdPartyBrandParams,
    BrandShareAdapter as ThirdPartyBrandShareAdapter
} from "./adapter/thirdParty";
export { getEnterpriseKeyChain } from "./api/portal/getEnterpriseKeyChain";
export { getBrandShareKey } from "./api/portal/getBrandShareKey";
export {
    tryDecryptEnterpriseKeyChain,
    tryDecryptMerchantKeyChainByShareKey,
    tryDecryptBrandKeyChainByShareKey
} from "./keychain";

export interface DecryptError<T> {
    errCode: number;
    message: string;
    response?: T;
}

export interface DataEncryptor {
    encryptRequest(request: object, api: string): Promise<ApiResult<object>>;
    decryptResponse(response: object, api: string): Promise<ApiResult<object>>;
    encryptImage(label: string, image: Blob): Promise<ApiResult<Blob>>;
    decryptImage(label: string, url: string): Promise<ApiResult<string>>;
}

type InnerAuthParams = Enum3<Enum2<MerchantParams, PortalBrandShareParams>, EnduserAuthParams, Enum2<ThirdPartyMerchantParams, ThirdPartyBrandParams>>;

export class AuthParams {
    private authParams: InnerAuthParams;
    constructor(authParams: InnerAuthParams) {
        this.authParams = authParams;
    }
    //VNO或者品牌操作员使用portal平台的授权
    static fromPortalMerchant(merchantParams: MerchantParams): AuthParams {
        return new AuthParams(Enum3.First(Enum2.First(merchantParams)));
    }
    //reseller使用portal平台的授权
    static fromPortalBrandShareKey(brandShareParams: PortalBrandShareParams): AuthParams {
        return new AuthParams(Enum3.First(Enum2.Second(brandShareParams)));
    }
    //终端用户使用app的授权
    static fromEnduserShareKey(enduserAuthParams: EnduserAuthParams): AuthParams {
        return new AuthParams(Enum3.Second(enduserAuthParams));
    }
    //第三方系统使用VNO分享码的授权
    static fromThirdPartyMerchantShareKey(merchantShareParams: ThirdPartyMerchantParams): AuthParams {
        return new AuthParams(Enum3.Third(Enum2.First(merchantShareParams)));
    }
    //第三方系统使用品牌分享码的授权
    static fromThirdPartyBrandShareKey(brandShareParams: ThirdPartyBrandParams): AuthParams {
        return new AuthParams(Enum3.Third(Enum2.Second(brandShareParams)));
    }

    buildDataEncryptor(): DataEncryptor {
        return this.authParams.mapAll((portalParams) => {
            return portalParams.mapAll<DataEncryptor>((merchantParams) => {
                return new CommonDataEncryptor(MerchantAdapter, merchantParams);
            }, (brandShareParams) => {
                return new CommonDataEncryptor(PortalBrandShareAdapter, brandShareParams);
            });
        }, (enduserAuthParams) => {
            return new CommonDataEncryptor(EnduserShareAdapter, enduserAuthParams);
        }, (thirdPartyParams) => {
            return thirdPartyParams.mapAll<DataEncryptor>((merchantShareParams) => {
                return new CommonDataEncryptor(ThirdPartyMerchantShareAdapter, merchantShareParams);
            }, (brandShareParams) => {
                return new CommonDataEncryptor(ThirdPartyBrandShareAdapter, brandShareParams);
            });
        });
    }
}

interface EncryptedData {
    //数据，可以是明文或者密文
    //当是密文的时候，约定以base64格式编码
    data: string;
    metaKeyId?: number; //metaKey的id，没有表示未加密
}

function getFileName(headers: Headers): string | undefined {
    let contentDisposition = headers.get('content-disposition');
    if (contentDisposition) {
        let name = contentDisposition.split('filename=').pop();
        if (name) {
            return name.replace(/^\"/, '').replace(/\"$/, '');
        }
    }
    return undefined;
}

export interface ApiResult<T> {
    errCode: number;
    message?: string;
    result?: T;
}

function resultToApiResult<T>(result: Result<T, ApiError>): ApiResult<T> {
    let data: T;
    let apiError: ApiError;
    result.match((val) => {
        data = val;
    }, (err) => {
        apiError = err;
    });
    if (result.isOk()) {
        return {
            errCode: 0,
            result: data!
        };
    } else {
        return apiError!;
    }
}

export class CommonDataEncryptor<AuthParams, KeyChain> {
    private adapter: Adapter<AuthParams, KeyChain>;
    private keyChainCache: CacheMgr<KeyChain, ApiError>;
    private encryptedMetaCache: CacheMgr<EncryptedMeta[], ApiError>;

    constructor(adapter: Adapter<AuthParams, KeyChain>, authParams: AuthParams) {
        this.adapter = adapter;
        this.keyChainCache = buildCacheMgr(async () => {
            return adapter.queryKeyChain(authParams) as any;
        }, 30 * 1000); //30秒过期
        this.encryptedMetaCache = buildCacheMgr(async () => {
            return adapter.queryEncryptedMeta(authParams);
        }, 30 * 1000); //30秒过期
    }

    private async ensureEncryptedMeta(): Promise<Result<EncryptedMeta[], ApiError>> {
        return this.encryptedMetaCache.getFreshData();
    }

    //此处开放给数据升级场景使用
    async lookupMetaKey(): Promise<Result<{
        metaKeyId: number;
        metaKey: AesKey256;
    } | undefined, ApiError>> {
        let result = await this.keyChainCache.getFreshData();
        return result.map((keyChain) => {
            return this.adapter.lookupMetaKey(keyChain);
        });
    }

    private async getMetaKeyById(metaKeyId: number): Promise<Result<Option<AesKey256>, ApiError>> {
        let result1 = await this.keyChainCache.getFreshData();
        if (result1.isErr()) {
            return result1 as any;
        }
        let keyChain: KeyChain;
        result1.match((data) => {
            keyChain = data;
        });
        let metaKey: Option<AesKey256> = this.adapter.findMetaKeyById(keyChain!, metaKeyId);
        //已有的集合里找不到，需要再强制查询一次
        if (metaKey.isNone()) {
            this.keyChainCache.clearData();
            let result2 = await this.keyChainCache.getFreshData();
            return result2.map((keyChain) => {
                return this.adapter.findMetaKeyById(keyChain, metaKeyId);
            });
        } else {
            return Result.Ok(metaKey);
        }
    }

    /**
     * 解密单个响应字段
     * @param value 字段内容
     * @returns 如果完全解密成功，返回Ok，如果解密失败，并且可以忽略错误，则返回的错误信息里包含response属性，否则response为空
     */
    private async decryptFieldInner(value: string, context?: any): Promise<Result<string, DecryptError<string>>> {
        let data: EncryptedData;
        try {
            data = JSON.parse(value);
        } catch (ex) {
            console.error("Invalid data format:", ex, "data context:", context);
            return Result.Err({
                errCode: -1,
                message: "Invalid data format!",
                response: value
            });
        }
        if (data.metaKeyId) {
            let result2 = await this.getMetaKeyById(data.metaKeyId);
            if (result2.isErr()) {
                return result2 as any;
            }
            let metaKey: Option<AesKey256>;
            result2.match((data) => {
                metaKey = data;
            });
            return new Promise((resolve, reject) => {
                metaKey.match((metaKey) => {
                    if ("string" === typeof data.data) {
                        try {
                            let plain = metaKey.decrypt(Bytes.tryFromBase64(data.data));
                            let decoder = new TextDecoder();
                            resolve(Result.Ok(decoder.decode(plain.getRaw())));
                        } catch (ex) {
                            console.error(ex);
                            resolve(Result.Err({
                                errCode: -1,
                                message: "Decrypt data failed.",
                                response: value
                            }));
                        }
                    } else {
                        //加密的数据不是字符串，无法解密，异常情况
                        resolve(Result.Err({
                            errCode: -1,
                            message: "Invalid encrypted data.",
                            response: value
                        }));
                    }
                }, () => {
                    //没有找到加密key，异常情况
                    resolve(Result.Err({
                        errCode: -1,
                        message: "Can not find the key to decrypt data.",
                        response: undefined
                    }));
                });
            });
        } else {
            //没有metaKeyId，非加密数据
            return Result.Ok(data.data);
        }
    }

    /**
     * 加密文本数据
     * @param value 文本数据
     * @returns 文本数据密文
     */
    async encryptField(value: string): Promise<ApiResult<string>> {
        let result = await this.encryptFieldInner(value);
        return resultToApiResult(result);
    }

    private async encryptFieldInner(value: string): Promise<Result<any, ApiError>> {
        let result2 = await this.lookupMetaKey();
        if (result2.isErr()) {
            return result2 as any;
        }
        let keyInfo: {
            metaKeyId: number;
            metaKey: AesKey256;
        } | undefined;
        result2.match((data) => {
            keyInfo = data;
        });
        if (!keyInfo) {
            //没有找到加密key，异常情况
            return Result.Err({
                errCode: -1,
                message: "Can not find the key to encrypt data."
            });
        }
        if ("string" === typeof value) {
            return Result.Ok(JSON.stringify({
                metaKeyId: keyInfo.metaKeyId,
                data: keyInfo.metaKey.encrypt(Bytes.fromUtf8(value)).toBase64()
            }));
        } else if ("object" === typeof value && null != value) {
            //未知对象，直接报错
            return Result.Err({
                errCode: -1,
                message: "Can not encrypt non string data."
            });
        } else {
            //非字符串数据不加密
            return Result.Ok(value);
        }
    }

    async decryptField(value: string): Promise<ApiResult<string>> {
        let result = await this.decryptFieldInner(value);
        return resultToApiResult(result);
    }

    async encryptRequest(request: object, api: string): Promise<ApiResult<object>> {
        let action: () => Promise<Result<object, ApiError>> = async (): Promise<Result<object, ApiError>> => {
            let result1 = await this.ensureEncryptedMeta();
            if (result1.isErr()) {
                return result1 as any;
            }
            let encryptedMetas: EncryptedMeta[];
            result1.match((data) => {
                encryptedMetas = data;
            });
            let apiMetas = encryptedMetas!.filter((item) => {
                return MetaType.Api == item.metaType && (item.metaData as ApiMeta).api.test(api);
            }).map((item) => item.metaData as ApiMeta);
            if (1 < apiMetas.length) {
                console.error("More than one encryption configuration matched", api);
                return Result.Err({
                    errCode: -1,
                    message: "More than one encryption configuration matched!"
                });
            }
            let apiMeta = apiMetas[0];
            if (apiMeta) {
                //api路径命中
                let _this = this;
                try {
                    let newRequest = await cleanData(null == request ? request : JSON.parse(JSON.stringify(request)), {
                        match(dataPath: string): boolean {
                            return (apiMeta.request.fields || []).some((field) => {
                                return dataPath == field.path;
                            });
                        },
                        async replace(data, dataPath): Promise<any> {
                            return resultToPromise(await _this.encryptFieldInner(data));
                        }
                    });
                    return Result.Ok(newRequest);
                } catch (ex) {
                    return Result.Err(ex as any);
                }
            } else {
                return Result.Ok(request);
            }
        };
        let result = await action();
        return resultToApiResult(result);
    }

    async decryptResponse(response: object, api: string): Promise<ApiResult<object>> {
        let action: () => Promise<Result<object, DecryptError<object>>> = async (): Promise<Result<object, DecryptError<object>>> => {
            let result1 = await this.ensureEncryptedMeta();
            if (result1.isErr()) {
                return result1.mapErr((err) => {
                    return {
                        errCode: err.errCode,
                        message: err.message,
                        response: undefined
                    };
                });
            }
            let encryptedMetas: EncryptedMeta[];
            result1.match((data) => {
                encryptedMetas = data;
            });
            let apiMetas = encryptedMetas!.filter((item) => {
                return MetaType.Api == item.metaType && (item.metaData as ApiMeta).api.test(api);
            }).map((item) => item.metaData as ApiMeta);
            if (1 < apiMetas.length) {
                console.error("More than one encryption configuration matched", api);
                return Result.Err({
                    errCode: -1,
                    message: "More than one encryption configuration matched!"
                });
            }
            let apiMeta = apiMetas[0];
            if (apiMeta) {
                //api路径命中
                let _this = this;
                try {
                    let err: ApiError | undefined;
                    let newData = await cleanData(null == response ? response : JSON.parse(JSON.stringify(response)), {
                        match(path: string): boolean {
                            return (apiMeta.response.fields || []).some((field) => {
                                return path == field.path;
                            });
                        },
                        async replace(dataStr: string, dataPath?: string): Promise<any> {
                            if (null == dataStr) {
                                return dataStr;
                            }
                            let result = await _this.decryptFieldInner(dataStr, {
                                api: api,
                                dataPath: dataPath
                            });
                            return new Promise(function (resolve, reject) {
                                result.match(resolve, (error) => {
                                    if (null != error.response) {
                                        if (!err) {
                                            err = {
                                                errCode: error.errCode,
                                                message: error.message
                                            };
                                        }
                                        //虽然单个字段解密失败了，但是仍然让它走完解密流程
                                        resolve(error.response);
                                    } else {
                                        reject(error);
                                    }
                                });
                            });
                        }
                    });
                    if (err) {
                        return Result.Err({
                            errCode: err.errCode,
                            message: err.message,
                            response: newData
                        });
                    } else {
                        return Result.Ok(newData);
                    }
                } catch (ex) {
                    return Result.Err(ex as any);
                }
            } else {
                return Result.Ok(response);
            }
        };
        let result = await action();
        return resultToApiResult(result);
    }

    /**
     * 加密二进制文件
     * @param label 二进制文件标签
     * @param blob 二进制文件数据
     * @returns 二进制文件密文
     */
    async encryptObject(label: string, blob: Blob): Promise<ApiResult<Blob>> {
        let action: () => Promise<Result<Blob, ApiError>> = async (): Promise<Result<Blob, ApiError>> => {
            let result1 = await this.ensureEncryptedMeta();
            if (result1.isErr()) {
                return result1 as any;
            }
            let encryptedMetas: EncryptedMeta[];
            result1.match((data) => {
                encryptedMetas = data;
            });
            let meta = encryptedMetas!.filter((item) => {
                return MetaType.File == item.metaType && label == (item.metaData as FileMeta).label;
            })[0];
            if (!meta) {
                //没有找到对应的businessType，是不需要加密的图片类型
                return Result.Ok(blob);
            }
            let result2 = await this.lookupMetaKey();
            if (result2.isErr()) {
                return result2 as any;
            }
            let keyInfo: {
                metaKeyId: number;
                metaKey: AesKey256;
            } | undefined;
            result2.match((data) => {
                keyInfo = data;
            });
            if (!keyInfo) {
                //没有找到加密key，异常情况
                return Result.Err({
                    errCode: -1,
                    message: "Can not find a key to encrypt image data."
                });
            }
            let bytes = new Bytes(new Uint8Array(await blob.arrayBuffer()));
            let cipher = keyInfo.metaKey.encrypt(bytes);
            return Result.Ok(new Blob([encodeBlob(cipher.getRaw(), keyInfo.metaKeyId)]));
        };
        let result = await action();
        return resultToApiResult(result);
    }

    /**
     * 加密图片
     * @param label 图片标签
     * @param image 图片数据
     * @returns 图片密文
     */
    async encryptImage(label: string, image: Blob): Promise<ApiResult<Blob>> {
        return this.encryptObject(label, image);
    }

    /**
     * 解密二进制文件
     * @param label 二进制文件标签
     * @param blob 二进制文件数据
     * @returns 二进制文件明文
     */
    async decryptObject(label: string, blob: Blob): Promise<ApiResult<Blob>> {
        let action: () => Promise<Result<Blob, ApiError>> = async (): Promise<Result<Blob, ApiError>> => {
            let result1 = await this.ensureEncryptedMeta();
            if (result1.isErr()) {
                return result1 as any;
            }
            let encryptedMetas: EncryptedMeta[];
            result1.match((data) => {
                encryptedMetas = data;
            });
            let meta = encryptedMetas!.filter((item) => {
                return MetaType.File == item.metaType && label == (item.metaData as FileMeta).label;
            })[0];
            if (!meta) {
                //没有找到对应的businessType，是不需要解密的图片类型
                return Result.Ok(blob);
            }
            let bytes = await blob.arrayBuffer();
            let blobData: BlobData;
            try {
                blobData = tryDecodeBlob(new Uint8Array(bytes));
            } catch (ex) {
                console.error(ex);
                return Result.Err({
                    errCode: -1,
                    message: "Decode image data failed."
                });
            }
            if (blobData.metaKeyId) {
                let result2 = await this.getMetaKeyById(blobData.metaKeyId);
                if (result2.isErr()) {
                    return result2 as any;
                }
                let metaKey: Option<AesKey256>;
                result2.match((data) => {
                    metaKey = data;
                });
                return new Promise((resolve, reject) => {
                    metaKey.match((metaKey) => {
                        try {
                            let plain = metaKey.decrypt(new Bytes(blobData.data));
                            resolve(Result.Ok(new Blob([plain.getRaw()], { type: blob.type })));
                        } catch (ex) {
                            console.error(ex);
                            resolve(Result.Err({
                                errCode: -1,
                                message: "Decrypt image data failed."
                            }));
                        }
                    }, () => {
                        resolve(Result.Err({
                            errCode: -1,
                            message: "Can not find the key to decrypt image data."
                        }));
                    });
                });
            } else {
                return Result.Ok(new Blob([blobData.data], { type: blob.type }));
            }
        };
        let result = await action();
        return resultToApiResult(result);
    }

    /**
     * 解密二进制文件（以url的方式表达）
     * @param label 二进制文件标签
     * @param url 二进制文件地址
     * @returns 处理后的二进制文件地址
     */
    async decryptUrl(label: string, url: string): Promise<Result<Enum2<string, Blob | File>, ApiError>> {
        let result1 = await this.ensureEncryptedMeta();
        if (result1.isErr()) {
            return result1 as any;
        }
        let encryptedMetas: EncryptedMeta[];
        result1.match((data) => {
            encryptedMetas = data;
        });
        let meta = encryptedMetas!.filter((item) => {
            return MetaType.File == item.metaType && label == (item.metaData as FileMeta).label;
        })[0];
        if (!meta) {
            //没有找到对应的businessType，是不需要解密的图片类型
            return Result.Ok(Enum2.First(url));
        }
        let resp = await window.fetch(url);
        if (!resp.ok) {
            throw resp;
        }
        let contentType = resp.headers.get('content-type');
        if (contentType && contentType.startsWith("application/json")) {
            let json = await resp.json();
            throw json;
        }
        let fileName = getFileName(resp.headers);
        let respBlob = await resp.blob();
        let decryptResult = await this.decryptObject(label, respBlob);
        if (0 == decryptResult.errCode) {
            let ret: Blob | File;
            if (fileName) {
                ret = new File([decryptResult.result!], fileName);
            } else {
                ret = decryptResult.result!;
            }
            return Result.Ok(Enum2.Second(ret));
        } else {
            return Result.Err({
                errCode: decryptResult.errCode,
                message: decryptResult.message!
            });
        }
    }

    /**
     * 解密图片
     * @param label 图片标签
     * @param url 图片地址
     * @returns 处理后的图片地址
     */
    async decryptImage(label: string, url: string): Promise<ApiResult<string>> {
        let result = await this.decryptUrl(label, url);
        return resultToApiResult(result.map((ret) => {
            return ret.mapAll((url) => {
                return url;
            }, (blob) => {
                return URL.createObjectURL(blob);
            });
        }));
    }

}
